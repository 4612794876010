<template>
    <div>
        <AgenciesList />
    </div>
</template>

<script>
import AgenciesList from '@/components/AgenciesList.vue'
export default {
  components: {
    AgenciesList
  }
}
</script>
