<template>
    <div>
        <b-container>
            <section class="agencies">
                <div class="sector pb-0">
                    <h2 class="main-title">Agencies</h2>
                    <p class="main-subtitle">Discover the digital marketplace</p>
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="filterAgency" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterAgency" class="custom-search-bar" type="text" placeholder="Search by company name or city or country" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedService" @click.prevent="clearService" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedService" class="custom-sorter" labelTitle = "Sort by service" v-model="selectedService" :options="services" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedCountry" @click.prevent="clearCountry" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedCountry" class="custom-sorter" labelTitle = "Sort by country" v-model="selectedCountry" :options="countries" :searchable = true />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedProjectSize" @click.prevent="clearProjectSize" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedProjectSize" class="custom-sorter" labelTitle = "Sort by minimum project size" v-model="selectedProjectSize" :options="projectSizes" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedEmployees" @click.prevent="clearEmployees" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedEmployees" class="custom-sorter" labelTitle = "Sort by employees number" v-model="selectedEmployees" :options="employees" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedYear" @click.prevent="clearYear" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <date-picker v-model="selectedYear" class="custom-datepicker" lang="en" type="year" format="YYYY" :clearable="false" placeholder="Sort by founding year"></date-picker>
                            </div>
                        </b-col>
                    </b-row>
                    <div v-if="getFilteredAgencies.length == 0 && loader" class="mb-3">
                        <Spinner size="medium" line-fg-color="#e91e63" />
                    </div>
                    <div id="top" v-else>
                        <div v-if="filterAgency != '' || selectedCountry != null || selectedService != null || selectedProjectSize != null || selectedEmployees != null || selectedYear != null">
                            <paginate name="agenciesStore" :list="getFilteredAgencies" tag="div" :per="9" class="paginate-list">
                            <b-row>
                                <b-col v-for="agency in paginated('agenciesStore')" :key="agency.id" cols="12" md="6" lg="6" xl="4">
                                    <div class="agencies__element">
                                        <div v-if="agency.stability" class="agencies__stats">
                                            <span class="agencies__star-tooltip" v-b-tooltip title="Statistics included"><simple-line-icons icon="chart" size="small" color="#504e70" /></span>
                                        </div>
                                        <div v-if="agency.leader" class="agencies__leader">
                                            <span class="agencies__star-tooltip" v-b-tooltip title="Market leader"><simple-line-icons icon="diamond" size="small" color="#504e70" /></span>
                                        </div>
                                        <div v-if="agency.ribbon" class="custom-ribbon custom-ribbon--right">
                                            <span class="custom-ribbon__text custom-ribbon__text--blue">Featured</span>
                                        </div>
                                        <div class="agencies__image" v-lazy:background-image="agency.url"></div>
                                        <div class="agencies__content">
                                            <h2 class="agencies__title" v-line-clamp:24="2">{{ agency.name }}</h2>
                                            <p v-html="agency.desc" class="agencies__text" v-line-clamp:24="3"></p>
                                            <div v-if="agency.rating" class="agencies__rating agencies__rating--active">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <span :id="agency.id" class="agencies__star-tooltip"><star-rating :star-size="18" border-color="transparent" :show-rating="false" :rating="agency.rating" :increment="0.01" :read-only="true"></star-rating></span>
                                                    </b-col>
                                                    <b-tooltip :target="agency.id">
                                                        Rating: {{ agency.rating }}
                                                    </b-tooltip>
                                                </b-row>
                                            </div>
                                            <div v-else class="agencies__rating"></div>
                                            <b-row>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Minimum project size"><simple-line-icons icon="tag" size="small" color="#504e70" /></span>{{ agency.project.text }}</span>
                                                </b-col>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Employees"><simple-line-icons icon="people" size="small" color="#504e70" /></span>{{ agency.employees.text }}</span>
                                                </b-col>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Founded"><simple-line-icons icon="flag" size="small" color="#504e70" /></span>{{ agency.founded }}</span>
                                                </b-col>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ agency.city }}, {{ agency.country.text }}</span>
                                                </b-col>
                                                <b-col v-if="agency.email" cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="E-mail"><simple-line-icons icon="envelope" size="small" color="#504e70" /></span><a class="agencies__email-link" :href="'mailto:'+ agency.email">{{ agency.email }}</a></span>
                                                </b-col>
                                                <b-col v-if="agency.phone" cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Phone number"><simple-line-icons icon="phone" size="small" color="#504e70" /></span>{{ agency.phone }}</span>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="agencies__details">
                                            <b-row>
                                                <b-col cols="12" sm="6">
                                                    <a class="agencies__button agencies__button--website" :href="agency.website" target="_blank"><simple-line-icons icon="globe" size="small" color="#504e70" />Visit website</a>
                                                </b-col>
                                                <b-col cols="12" sm="6">
                                                    <router-link :to="{ name: 'agency', params: { id: agency.id } }" class="agencies__button agencies__button--profile"><simple-line-icons icon="grid" size="small" color="#fff" />View profile</router-link>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </paginate>
                        <paginate-links  @change="scrollToTop" v-if="getFilteredAgencies.length != 0" for="agenciesStore" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                    </div>
                    <div v-else>
                            <paginate name="agenciesList" :list="getStoreAgencies" tag="div" :per="9" class="paginate-list">
                            <b-row>
                                <b-col v-for="agency in paginated('agenciesList')" :key="agency.id" cols="12" md="6" lg="6" xl="4">
                                    <div class="agencies__element">
                                        <div v-if="agency.stability" class="agencies__stats">
                                            <span class="agencies__star-tooltip" v-b-tooltip title="Statistics included"><simple-line-icons icon="chart" size="small" color="#504e70" /></span>
                                        </div>
                                        <div v-if="agency.leader" class="agencies__leader">
                                            <span class="agencies__star-tooltip" v-b-tooltip title="Market leader"><simple-line-icons icon="diamond" size="small" color="#504e70" /></span>
                                        </div>
                                        <div v-if="agency.ribbon" class="custom-ribbon custom-ribbon--right">
                                            <span class="custom-ribbon__text custom-ribbon__text--blue">Featured</span>
                                        </div>
                                        <div class="agencies__image" v-lazy:background-image="agency.url"></div>
                                        <div class="agencies__content">
                                            <h2 class="agencies__title" v-line-clamp:24="2">{{ agency.name }}</h2>
                                            <p v-html="agency.desc" class="agencies__text" v-line-clamp:24="3"></p>
                                            <div v-if="agency.rating" class="agencies__rating agencies__rating--active">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <span :id="agency.id" class="agencies__star-tooltip"><star-rating :star-size="18" border-color="transparent" :show-rating="false" :rating="agency.rating" :increment="0.01" :read-only="true"></star-rating></span>
                                                    </b-col>
                                                    <b-tooltip :target="agency.id">
                                                        Rating: {{ agency.rating }}
                                                    </b-tooltip>
                                                </b-row>
                                            </div>
                                            <div v-else class="agencies__rating"></div>
                                            <b-row>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Minimum project size"><simple-line-icons icon="tag" size="small" color="#504e70" /></span>{{ agency.project.text }}</span>
                                                </b-col>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Employees"><simple-line-icons icon="people" size="small" color="#504e70" /></span>{{ agency.employees.text }}</span>
                                                </b-col>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Founded"><simple-line-icons icon="flag" size="small" color="#504e70" /></span>{{ agency.founded }}</span>
                                                </b-col>
                                                <b-col cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ agency.city }}, {{ agency.country.text }}</span>
                                                </b-col>
                                                <b-col v-if="agency.email" cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="E-mail"><simple-line-icons icon="envelope" size="small" color="#504e70" /></span><a class="agencies__email-link" :href="'mailto:'+ agency.email">{{ agency.email }}</a></span>
                                                </b-col>
                                                <b-col v-if="agency.phone" cols="12">
                                                    <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Phone number"><simple-line-icons icon="phone" size="small" color="#504e70" /></span>{{ agency.phone }}</span>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="agencies__details">
                                            <b-row>
                                                <b-col cols="12" sm="6">
                                                    <a class="agencies__button agencies__button--website" :href="agency.website" target="_blank"><simple-line-icons icon="globe" size="small" color="#504e70" />Visit website</a>
                                                </b-col>
                                                <b-col cols="12" sm="6">
                                                    <router-link :to="{ name: 'agency', params: { id: agency.id } }" class="agencies__button agencies__button--profile"><simple-line-icons icon="grid" size="small" color="#fff" />View profile</router-link>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </paginate>
                        <paginate-links @change="scrollToTop" v-if="getStoreAgencies.length != 0" for="agenciesList" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                    </div>
                    </div>
                    <div v-if="getFilteredAgencies.length == 0 && getAgenciesPending && !loader" class="mb-3">
                        <p class="text-center result-text">No results found</p>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import StarRating from 'vue-star-rating'
import VSelect from '../assets/files/customSelect.esm.js'
import PrettyCheck from 'pretty-checkbox-vue/check'
import countriesList from '../assets/files/countriesList.js'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import DatePicker from 'vue2-datepicker'
import VuePaginate from 'vue-paginate'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    StarRating,
    VSelect,
    'p-check': PrettyCheck,
    Spinner,
    DatePicker
  },
  data: function () {
    return {
      loader: true,
      filterAgency: '',
      selectedCountry: null,
      selectedService: null,
      selectedProjectSize: null,
      selectedEmployees: null,
      selectedYear: null,
      countries: [],
      paginate: ['agenciesList', 'agenciesStore'],
      services: [
        { value: '0', text: 'Advertising' },
        { value: '1', text: 'Digital marketing' },
        { value: '2', text: 'Branding' },
        { value: '3', text: 'Social media marketing' },
        { value: '4', text: 'Web development' },
        { value: '5', text: 'Mobile app development' },
        { value: '6', text: 'eCommerce' },
        { value: '7', text: 'Big Data' },
        { value: '8', text: 'E-mail marketing' },
        { value: '9', text: 'Event marketing' },
        { value: '10', text: 'Cloud solutions' },
        { value: '11', text: 'SEO' },
        { value: '12', text: 'Graphic design' },
        { value: '13', text: 'Print design' },
        { value: '14', text: 'Video production' }
      ],
      projectSizes: [
        { value: '0', text: 'None' },
        { value: '1', text: '<$1000' },
        { value: '2', text: '$5000+' },
        { value: '3', text: '$10 000+' },
        { value: '4', text: '$25 000+' },
        { value: '5', text: '$50 000+' },
        { value: '6', text: '$75 000+' },
        { value: '7', text: '$100 000+' },
        { value: '8', text: '$200 000+' }
      ],
      employees: [
        { value: '0', text: '1-10' },
        { value: '1', text: '11-25' },
        { value: '2', text: '26-50' },
        { value: '3', text: '51-75' },
        { value: '4', text: '76-100' },
        { value: '5', text: '101-150' },
        { value: '6', text: '151-200' },
        { value: '7', text: '201-500' },
        { value: '8', text: '501+' }
      ]
    }
  },
  methods: {
    clearFilter () {
      this.filterAgency = ''
    },
    clearCountry () {
      this.selectedCountry = null
      if (document.querySelector('#selectedCountry li.selected') != undefined) {
        document.querySelector('#selectedCountry li.selected').classList.remove('selected')
      }
    },
    clearService () {
      this.selectedService = null
      if (document.querySelector('#selectedService li.selected') != undefined) {
        document.querySelector('#selectedService li.selected').classList.remove('selected')
      }
    },
    clearProjectSize () {
      this.selectedProjectSize = null
      if (document.querySelector('#selectedProjectSize li.selected') != undefined) {
        document.querySelector('#selectedProjectSize li.selected').classList.remove('selected')
      }
    },
    clearEmployees () {
      this.selectedEmployees = null
      if (document.querySelector('#selectedEmployees li.selected') != undefined) {
        document.querySelector('#selectedEmployees li.selected').classList.remove('selected')
      }
    },
    clearYear () {
      this.selectedYear = null
    },
    scrollToTop (toPage, fromPage) {
      window.history.replaceState(null, '', this.$route.path + '?page=' + toPage)

      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById('top')

      setTimeout(function () { moveTo.move(target) }, 200)
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAgencies')
  },
  created () {
    if (this.$route.query.service != undefined) {
      this.selectedService = this.services.find(item => item.value == this.$route.query.service.toString())
    }
    if (this.$route.query.agencyParam != undefined) {
      this.filterAgency = this.$route.query.agencyParam
    }

    this.countries = countriesList

    setTimeout(function () { this.loader = false }.bind(this), 5000)
  },
  computed: {
    ...mapGetters([
      'getAgencies',
      'getAgenciesPending'
    ]),
    getStoreAgencies () {
      var storeAgencies = Object.values(this.getAgencies).reverse().sort((a, b) => b.ribbon - a.ribbon)

      var sortedAgencies = storeAgencies.sort((a, b) => b.rating - a.rating)

      return sortedAgencies
    },
    getFilteredAgencies () {
      if (this.filterAgency != '' || this.selectedCountry != null || this.selectedService != null || this.selectedProjectSize != null || this.selectedEmployees != null || this.selectedYear != null) {
        var agencies = Object.values(this.getStoreAgencies).filter((agency) => {
          return (agency.name.toLowerCase().includes(this.filterAgency.toLowerCase()) || agency.city.toLowerCase().includes(this.filterAgency.toLowerCase()) || agency.country.text.toLowerCase().includes(this.filterAgency.toLowerCase()))
        })

        this.paginate.agenciesStore.page = 0

        if (this.$route.query.service != undefined) {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1 + '&service=' + this.$route.query.service)
        } else if (this.$route.query.agencyParam != undefined) {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1 + '&agencyParam=' + this.$route.query.agencyParam)
        } else {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1)
        }

        if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees == null && this.selectedYear == null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true
            }
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize != null && this.selectedEmployees != null && this.selectedYear != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            }
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize == null && this.selectedEmployees == null && this.selectedYear != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees != null && this.selectedYear != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedEmployees != null && this.selectedYear != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedEmployees == null && this.selectedYear != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees == null && this.selectedYear != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.founded == this.selectedYear.getFullYear()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.founded == this.selectedYear.getFullYear()
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            }
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize != null && this.selectedEmployees != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            }
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize == null && this.selectedEmployees != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedEmployees != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            }
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize == null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.country.text.toLowerCase() == this.selectedCountry.value
            }
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            }
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize != null && this.selectedService != null) {
          return agencies.filter((agencies) => {
            if (agencies.services.advertising == true && this.selectedService.value == 0) {
              return agencies.services.advertising == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.digital == true && this.selectedService.value == 1) {
              return agencies.services.digital == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.branding == true && this.selectedService.value == 2) {
              return agencies.services.branding == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.social == true && this.selectedService.value == 3) {
              return agencies.services.social == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.webDev == true && this.selectedService.value == 4) {
              return agencies.services.webDev == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.mobile == true && this.selectedService.value == 5) {
              return agencies.services.mobile == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.commerce == true && this.selectedService.value == 6) {
              return agencies.services.commerce == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.data == true && this.selectedService.value == 7) {
              return agencies.services.data == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.emailMarketing == true && this.selectedService.value == 8) {
              return agencies.services.emailMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.eventMarketing == true && this.selectedService.value == 9) {
              return agencies.services.eventMarketing == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.cloud == true && this.selectedService.value == 10) {
              return agencies.services.cloud == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.seo == true && this.selectedService.value == 11) {
              return agencies.services.seo == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.graphic == true && this.selectedService.value == 12) {
              return agencies.services.graphic == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.print == true && this.selectedService.value == 13) {
              return agencies.services.print == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            } else if (agencies.services.video == true && this.selectedService.value == 14) {
              return agencies.services.video == true && agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
            }
          })
        }

        // ==================================================================
        else if (this.selectedCountry != null && this.selectedProjectSize != null && this.selectedEmployees != null && this.selectedYear != null) {
          return agencies.filter((agencies) => {
            return agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize == null && this.selectedEmployees == null && this.selectedYear != null) {
          return agencies.filter((agencies) => {
            return agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.founded == this.selectedYear.getFullYear()
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees != null && this.selectedYear != null) {
          return agencies.filter((agencies) => {
            return agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedEmployees != null && this.selectedYear != null) {
          return agencies.filter((agencies) => {
            return agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.founded == this.selectedYear.getFullYear()
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedEmployees == null && this.selectedYear != null) {
          return agencies.filter((agencies) => {
            return agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.founded == this.selectedYear.getFullYear()
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees == null && this.selectedYear != null) {
          return agencies.filter((agencies) => {
            return agencies.founded == this.selectedYear.getFullYear()
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize == null && this.selectedEmployees != null) {
          return agencies.filter((agencies) => {
            return agencies.employees.text.toLowerCase() == this.selectedEmployees.text
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize != null && this.selectedEmployees != null) {
          return agencies.filter((agencies) => {
            return agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase() && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize == null && this.selectedEmployees != null) {
          return agencies.filter((agencies) => {
            return agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.employees.text.toLowerCase() == this.selectedEmployees.text
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null && this.selectedEmployees != null) {
          return agencies.filter((agencies) => {
            return agencies.employees.text.toLowerCase() == this.selectedEmployees.text && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize == null) {
          return agencies.filter((agencies) => {
            return agencies.country.text.toLowerCase() == this.selectedCountry.value
          })
        } else if (this.selectedCountry == null && this.selectedProjectSize != null) {
          return agencies.filter((agencies) => {
            return agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
          })
        } else if (this.selectedCountry != null && this.selectedProjectSize != null) {
          return agencies.filter((agencies) => {
            return agencies.country.text.toLowerCase() == this.selectedCountry.value && agencies.project.text.toLowerCase() == this.selectedProjectSize.text.toLowerCase()
          })
        } else {
          return agencies
        }
      } else {
        if (this.$route.query.page != undefined) {
          this.paginate.agenciesList.page = this.$route.query.page - 1
        } else {
          this.paginate.agenciesList.page = 0
        }
        var agencies = Object.values(this.getStoreAgencies)
        return agencies
      }
    }
  }
}
</script>
